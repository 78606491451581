<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>Answer the two questions based on the structure of the molecule shown below.</p>

      <p>
        <img :src="bsImage" width="150" height="150" class="ml-8" />
      </p>

      <p>What is the shape of the molecule?</p>

      <v-radio-group v-model="inputs.input1">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block pl-8"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p>What are the bond angles in the molecule?</p>

      <v-radio-group v-model="inputs.input2">
        <v-radio
          v-for="option in options2"
          :key="option.value"
          class="d-inline-block pl-8"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

const MOL_BH3 = 1;
const MOL_CH4 = 2;
const MOL_H2O = 3;
const MOL_NH3 = 4;
const MOL_OH = 5;

export default {
  name: 'Question41',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: '$\\text{Trigonal Planar}$', value: 'a'},
        {text: '$\\text{Tetrahedral}$', value: 'b'},
        {text: '$\\text{Trigonal Pyramidal}$', value: 'c'},
        {text: '$\\text{Bent}$', value: 'd'},
        {text: '$\\text{Octahedral}$', value: 'e'},
        {text: '$\\text{Trigonal Bipyramidal}$', value: 'f'},
        {text: '$\\text{T-shape}$', value: 'g'},
      ],
      options2: [
        {text: '$90^\\omicron$', value: 'a'},
        {text: '$< 109.5^\\omicron$', value: 'b'},
        {text: '$~109.5^\\omicron$', value: 'c'},
        {text: '$~120^\\omicron$', value: 'd'},
        {text: '$180^\\omicron$', value: 'e'},
      ],
    };
  },
  computed: {
    imageNumber() {
      return this.taskState.getValueBySymbol('arrayVariable').content.value;
    },
    bsImage() {
      if (this.imageNumber === MOL_BH3) {
        return `/img/assignments/BH3-bs.png`;
      } else if (this.imageNumber === MOL_CH4) {
        return `/img/assignments/CH4-bs.png`;
      } else if (this.imageNumber === MOL_H2O) {
        return `/img/assignments/H2O-bs.png`;
      } else if (this.imageNumber === MOL_NH3) {
        return `/img/assignments/NH3-bs.png`;
      } else if (this.imageNumber === MOL_OH) {
        return `/img/assignments/Oh-bs.png`;
      }
    },
  },
};
</script>
